// src/App.js

import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { useQuery } from 'react-query';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import BuyNowButton from './BuyNowButton'; // Import the reusable component
import './App.css'; // Import external CSS

const animatedComponents = makeAnimated();

function App() {
  // State variables
  const startYear = parseInt(process.env.REACT_APP_START_YEAR, 10) || 1970;
  const endYear = parseInt(process.env.REACT_APP_END_YEAR, 10) || 2023;
  const availableYears = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i).reverse();

  const [year, setYear] = useState(2023);
  const [gender, setGender] = useState('M'); // Initialize to 'M' for Male
  const [names, setNames] = useState([]);
  const [lastName, setLastName] = useState('');
  const [selectedName, setSelectedName] = useState(null);
  const [domainResults, setDomainResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // React Query: Fetch names based on year and gender
  const fetchNames = async ({ queryKey }) => {
    const [, selectedYear, selectedGender] = queryKey;

    // Validate that year is a number
    if (isNaN(selectedYear)) {
      throw new Error('Invalid year provided.');
    }

    const { data, error, count } = await supabase
      .from('names')
      .select('*', { count: 'exact' })
      .eq('Year', selectedYear)
      .eq('Gender', selectedGender)
      .order('Count', { ascending: false });

    if (error) {
      console.error('Supabase Error fetching names:', error);
      throw error;
    }

    if (!data) {
      console.error('Supabase returned no data for names.');
      throw new Error('No data returned');
    }

    return { data, count };
  };

  const {
    data: queryData,
    error: queryError,
    isLoading: queryLoading,
  } = useQuery(
    ['names', year, gender],
    fetchNames,
    {
      enabled: Number.isInteger(year) && !!gender, // Only run the query if year and gender are valid
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  // Update names when query data changes
  useEffect(() => {
    if (queryData) {
      const nameOptions = queryData.data.map((nameObj, index) => ({
        value: nameObj.Name,
        label: `${index + 1}. ${nameObj.Name}`,
      }));
      setNames(nameOptions);
    }
  }, [queryData]);

  // Handle domain availability check
  const handleDomainCheck = async () => {
    if (!selectedName || !lastName) {
      alert('Please select a name and enter your last name.');
      return;
    }

    // Simple regex to allow only letters and hyphens
    const nameRegex = /^[A-Za-z-]+$/;
    if (!nameRegex.test(lastName)) {
      alert('Please enter a valid last name (letters and hyphens only).');
      return;
    }

    const fullName = `${selectedName.value}${lastName}`;
    setIsLoading(true);
    setDomainResults([]);
    setError('');
    setSuccessMessage('');

    console.log(`Checking domains for: ${fullName}`);

    try {
      // Define the extensions to check
      const extensionsToCheck = ['com', 'net', 'org']; // Add more as needed

      // Fetch cached domains from Supabase
      const { data: cachedDomains, error: cacheError } = await supabase
        .from('domains')
        .select('*')
        .in('domain_name', extensionsToCheck.map(ext => `${fullName}.${ext}`));

      console.log('Fetched cached domains:', cachedDomains);
      console.log('Cache error:', cacheError);

      const domainsToCheck = [];
      const cachedResults = [];

      const now = new Date();

      // Determine which domains need to be checked via API
      if (cachedDomains) {
        cachedDomains.forEach(domain => {
          const lastChecked = new Date(domain.last_checked);
          const diffHours = (now - lastChecked) / (1000 * 60 * 60);

          if (diffHours < 24 && domain.status && domain.status !== 'Error') {
            // Use cached status
            cachedResults.push({
              fullName: fullName,
              extension: domain.domain_name.split('.').pop(),
              status: domain.status,
            });
          } else {
            // Needs to be checked via API
            const ext = domain.domain_name.split('.').pop();
            domainsToCheck.push(ext);
          }
        });
      } else {
        // If no cached domains, need to check all extensions
        domainsToCheck.push(...extensionsToCheck);
      }

      // If all domains are cached and recent
      if (domainsToCheck.length === 0) {
        setDomainResults(cachedResults);
        setIsLoading(false);
        return;
      }

      // Make API call to check domain statuses
      const response = await fetch(`/api/checkDomain?name=${encodeURIComponent(fullName)}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Network response was not ok');
      }
      const apiData = await response.json();
      console.log('API response data:', apiData);

      // Filter results based on domainsToCheck
      const filteredApiData = apiData.filter(domain => domainsToCheck.includes(domain.extension));

      // Update the 'domains' table with new statuses
      const upsertData = filteredApiData.map(domain => ({
        domain_name: `${domain.fullName}.${domain.extension}`,
        status: domain.status,
        last_checked: new Date().toISOString(),
      }));

      const { data: upserted, error: upsertError } = await supabase
        .from('domains')
        .upsert(upsertData, { onConflict: ['domain_name'] })
        .select();

      console.log('Upserted data:', upserted);
      console.log('Upsert error:', upsertError);

      if (upsertError) {
        console.error('Supabase Error updating domains:', upsertError);
        throw upsertError;
      }

      // Prepare new results from upserted data
      const newResults = upserted.map(domain => ({
        fullName: domain.domain_name.replace(`.${domain.extension}`, ''),
        extension: domain.extension,
        status: domain.status,
      }));

      // Combine cached and new results
      const combinedResults = [...cachedResults, ...newResults];

      setDomainResults(combinedResults);
      await trackSearch(selectedName.value, lastName);
    } catch (err) {
      console.error('Error checking domain availability:', err);
      setError(
        err.message ||
          'There was an error checking the domain availability. Please try again later.'
      );
    }

    setIsLoading(false);
  };

  // Function to track the search in the 'searches' table
  const trackSearch = async (firstName, lastName) => {
    try {
      const { data, error } = await supabase
        .rpc('increment_search_count', { p_first_name: firstName, p_last_name: lastName });

      if (error) {
        console.error('Supabase RPC Error tracking search:', error);
        // Optionally, handle the error (e.g., show a notification)
      } else {
        console.log('Search tracked successfully:', data);
        setSuccessMessage('Your search has been recorded.');
        // Optionally, remove the message after a delay
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    } catch (err) {
      console.error('Unexpected Error tracking search:', err);
      // Optionally, handle the error
    }
  };

  // Custom handler for creating new options
  const handleNameChange = (selectedOption) => {
    setSelectedName(selectedOption);
  };

  // Handler for when user types a new name
  const handleCreate = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setSelectedName(newOption);
  };

  return (
    <div className="container my-5">
      {/* Header with Logo */}
      <div className="header">
        <img
          src="/logos/logo-sq.png" // Path to your square logo
          alt="Logo"
          className="header-logo"
        />
        <h1>Baby Domain Names for Sale</h1>
      </div>

      {/* Display Errors */}
      {error && <div className="alert alert-danger">{error}</div>}
      {queryError && <div className="alert alert-danger">Error: {queryError.message}</div>}

      {/* Display Success Message */}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}

      {/* Loading Indicator for Names */}
      {queryLoading && <div className="alert alert-info">Loading names...</div>}

      {/* Handle No Names Found */}
      {!queryLoading && names.length === 0 && (
        <div className="alert alert-warning">No names available for the selected year and gender.</div>
      )}

      {/* Year Selector */}
      <div className="form-group">
        <label htmlFor="yearSelect">Select Year:</label>
        <select
          id="yearSelect"
          className="form-control"
          value={year || ''}
          onChange={e => setYear(parseInt(e.target.value, 10))}
          disabled={availableYears.length === 0 || queryLoading}
        >
          <option value="">Select Year</option>
          {availableYears.length > 0 ? (
            availableYears.map(y => (
              <option key={y} value={y}>{y}</option>
            ))
          ) : (
            <option value="">No Years Available</option>
          )}
        </select>
      </div>

      {/* Gender Selector */}
      <div className="form-group">
        <label htmlFor="genderSelect">Select Gender:</label>
        <select
          id="genderSelect"
          className="form-control"
          value={gender}
          onChange={e => {
            setGender(e.target.value);
          }}
          disabled={queryLoading}
        >
          <option value="F">Female</option>
          <option value="M">Male</option>
        </select>
      </div>

      {/* Name Combo Box */}
      <div className="form-group">
        <label htmlFor="nameSelect">Select or Enter a Name:</label>
        <CreatableSelect
          components={animatedComponents}
          isClearable
          isSearchable
          options={names}
          value={selectedName}
          onChange={handleNameChange}
          onCreateOption={handleCreate}
          placeholder="Type or select a name..."
          styles={{
            control: (provided) => ({
              ...provided,
              borderColor: '#ced4da',
              '&:hover': {
                borderColor: '#86b7fe',
              },
            }),
          }}
        />
      </div>

      {/* Last Name Input */}
      <div className="form-group">
        <label htmlFor="lastNameInput">Enter Your Last Name:</label>
        <input
          type="text"
          id="lastNameInput"
          className="form-control"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          placeholder="e.g., Smith"
        />
      </div>

      {/* Domain Checker */}
      <button
        className="btn btn-primary my-3"
        onClick={handleDomainCheck}
        disabled={isLoading}
      >
        {isLoading ? 'Checking...' : 'Check Domain Availability'}
      </button>

      {/* Display Selected Name */}
      {selectedName && lastName && (
        <div className="alert alert-info">
          You have selected: <strong>{selectedName.value} {lastName}</strong>
        </div>
      )}

    {/* Display Domain Results */}
{domainResults.length > 0 && (
  <div>
    <h3>Domain Availability for {selectedName.value} {lastName}:</h3>
    <ul className="list-group">
      {domainResults.map(domain => (
        <li
          key={`${domain.fullName}.${domain.extension}`}
          className="list-group-item d-flex justify-content-between align-items-center"
        >
          <span>
            {domain.fullName}.{domain.extension} - {domain.status}
          </span>
          {domain.status === 'Available' && (
            <div>
              {/* GoDaddy Buy Now Button */}
              <BuyNowButton
                href={`https://godaddy.com/buy/${domain.fullName}`}
                logoSrc="/logos/godaddy-logo.png" // Path to GoDaddy logo
                altText="GoDaddy Logo"
                buttonClass="btn-success"
              />

              {/* Namecheap Buy Now Button */}
              <BuyNowButton
                href={`https://namecheap.com/buy/${domain.fullName}`}
                logoSrc="/logos/namecheap-logo.png" // Path to Namecheap logo
                altText="Namecheap Logo"
                buttonClass="btn-warning"
              />
            </div>
          )}
        </li>
      ))}
    </ul>
  </div>
)}


      {/* Affiliate Disclaimer */}
      <p className="text-muted mt-4">
        By purchasing domains through the links above, you support our website at no additional cost to you.
      </p>
    </div>
  );
}

export default App;
