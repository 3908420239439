// src/BuyNowButton.js
import React from 'react';

const BuyNowButton = ({ href, logoSrc, altText, buttonClass }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={`btn ${buttonClass} btn-sm mr-2 d-flex align-items-center`}
    style={{ transition: 'transform 0.2s' }}
    onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.05)'}
    onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
  >
    <img
      src={logoSrc}
      alt={altText}
      style={{ width: '20px', height: 'auto', marginRight: '5px' }}
    />
    Buy Now
  </a>
);

export default BuyNowButton;
